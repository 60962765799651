import React, { useEffect, useState } from 'react';
import './styled.css';
import { TFileType } from '../Main';
import { useTranslation } from 'react-i18next';

export interface IOpenCapsuleModal {
  isOpen?: boolean;
  onClose?: () => void;
  content?: string;
  contentType: TFileType;
}

export const OpenCapsuleModal: React.FC<IOpenCapsuleModal> = ({
  isOpen,
  onClose = () => {},
  content,
  contentType,
}) => {
  const [isAnimate, setIsAnimate] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;

    if (isOpen) {
      setIsAnimate(true);

      timeout = setTimeout(() => {
        setIsAnimate(false);
      }, 6200);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [isOpen]);

  const onContentClick = (ev: React.MouseEvent) => {
    setIsAnimate(false);
    ev.preventDefault();
    ev.stopPropagation();
  };

  //Прекращает скролл содержимого страницы во время показа модалки
  //Нужно для отдельного скролла по содержимому модалки
  useEffect(() => {
    if (isOpen) {
      document.body.className = 'stop-scroll';
      document.documentElement.className = 'stop-scroll';
    } else {
      document.body.className = '';
      document.documentElement.className = '';
    }
  }, [isOpen]);

  return (
    <div
      onClick={onClose}
      className={`capsuleModal ${!isOpen ? 'capsuleModal-close' : ''} ${
        isAnimate ? 'capsuleModal-animate' : ''
      }`}>
      {isOpen ? (
        <>
          <span onClick={onContentClick} className="capsuleModal-block">
            <span className="capsuleModal-text1">
              {t('openingText1')}
              <span className="capsuleModal-text2">
                {t('openingText2')}
                <span className="capsuleModal-text3">{t('openingText3')}</span>
              </span>
            </span>
          </span>

          <div className="capsuleModal-content">
            {contentType === 'text' && (
              <div
                className="capsuleModal-content-text"
                onClick={onContentClick}>
                {content}
              </div>
            )}
            {contentType === 'video' && (
              <video
                className="capsuleModal-content-video"
                onClick={onContentClick}
                controls
                src={content}
              />
            )}
            {contentType === 'img' && (
              <img
                className="capsuleModal-content-video"
                onClick={onContentClick}
                src={content}
                alt="content"
              />
            )}
            {contentType === 'audio' && (
              <audio
                controls
                className="capsuleModal-content-video"
                onClick={onContentClick}
                src={content}
              />
            )}
            {/*<div className="capsuleModal-files">*/}
            {/*  <ul className="capsuleModal-files-list">*/}
            {/*    <li className="capsuleModal-files-item">ddd</li>*/}
            {/*    <li className="capsuleModal-files-item">ddd</li>*/}
            {/*  </ul>*/}
            {/*</div>*/}
          </div>

          <button className="capsuleModal-cross" onClick={onClose} />
        </>
      ) : null}
    </div>
  );
};
