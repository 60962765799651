import React from 'react';
import './styled.css';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export interface IClipboard {
  text?: string;
  label?: string;
}

export const Clipboard: React.FC<IClipboard> = ({ text = '', label = '' }) => {
  const { t } = useTranslation();
  const onClick = () => {
    navigator.clipboard?.writeText(text).then(() => {
      toast.success(t('clipboardMessage'), {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });
  };

  return (
    <div className="clipboard" onClick={onClick}>
      <div className="clipboard-label">{label}</div>

      <div className="clipboard-input">
        <div className="clipboard-text">{text}</div>
      </div>
    </div>
  );
};
