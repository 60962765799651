import { gql } from '@apollo/client';

export const GET_DECRYPTED_FILES_URLS = gql`
  query getDecryptedFileUrls($key: String!) {
    getDecryptedFileUrls(input: { privateKey: $key }) {
      fileUrls
    }
  }
`;

export const GET_RELEASE_TIME = gql`
  query getReleaseTime {
    getReleaseTime {
      releaseTime
    }
  }
`;

export const GET_SMART_ADDRESS = gql`
  query getSmartContractAddress {
    getSmartContractAddress {
      smartContractAddress
    }
  }
`;
