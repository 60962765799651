import React from 'react';
import './styled.css';

export interface ITimerItem {
  number: number;
  text: string;
}

export const TimerItem: React.FC<ITimerItem> = ({ number, text }) => {
  return (
    <div className="timerItem">
      <div className="timerItem-number">{number}</div>
      <div className="timerItem-text">{text}</div>
    </div>
  );
};
