import React, { useEffect, useRef, useState } from 'react';
import './styled.css';
import i18next from 'i18next';

export const LangSelect: React.FC = () => {
  const [selected, setSelected] = useState('ru');
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const callback = () => {
      setIsOpen(false);
    };
    document?.addEventListener('click', callback);

    return () => {
      document?.removeEventListener('click', callback);
    };
  }, []);

  const onOpen = (ev: React.MouseEvent<HTMLDivElement>) => {
    ev.stopPropagation();
    setIsOpen(!isOpen);
  };

  const onSelect = (lang: string) => () => {
    setSelected(lang);
    setIsOpen(false);
    i18next.changeLanguage(lang);
  };

  return (
    <div ref={ref} className="select-container">
      <div className="select" onClick={onOpen}>
        {selected.toLocaleUpperCase()}
      </div>

      {isOpen ? (
        <ul className="select-menu">
          <li onClick={onSelect('ru')} className="select-menu-item">
            RU
          </li>
          <li onClick={onSelect('en')} className="select-menu-item">
            EN
          </li>
        </ul>
      ) : null}
    </div>
  );
};
