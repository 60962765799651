import React from 'react';
import './styled.css';

export interface IInput {
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}

export const Input: React.FC<IInput> = ({
  label,
  value = '',
  onChange = () => {},
  placeholder,
}) => {
  const onKeyChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChange(ev.currentTarget?.value);
  };

  return (
    <label className="input">
      <div className="input-label">{label}</div>

      <input
        value={value}
        onChange={onKeyChange}
        className="input-input"
        placeholder={placeholder}
      />
    </label>
  );
};
