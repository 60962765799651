import React from 'react';
import './styled.css';
import loader from '../../assets/loader.svg';

export interface IButton {
  text?: string;
  isLoading?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const Button: React.FC<IButton> = ({
  onClick,
  text,
  isLoading = false,
  disabled = false,
}) => {
  return (
    <button disabled={disabled} className="button" onClick={onClick}>
      {isLoading ? (
        <img src={loader} alt="loader" className="button-loader" />
      ) : (
        text
      )}
    </button>
  );
};
