import React, { useEffect, useState } from 'react';
import './styles.css';
import capsule from '../../assets/capsule.png';
import { Timer } from '../Timer';
import { Input } from '../Input';
import { Button } from '../Button';
import { Clipboard } from '../Clipboard';
import { OpenCapsuleModal } from '../OpenCapsuleModal';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  GET_DECRYPTED_FILES_URLS,
  GET_RELEASE_TIME,
  GET_SMART_ADDRESS,
} from './schemas';
import { toast, ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { LangSelect } from '../LangSelect';

export type TGetReleaseTime = {
  getReleaseTime: {
    releaseTime: string;
  };
};

export type TGetDecryptedFileUrls = {
  getDecryptedFileUrls: {
    fileUrls: string[];
  };
};

export type TGetSmartContractAddress = {
  getSmartContractAddress: {
    smartContractAddress: string;
  };
};

export type TFileType = 'img' | 'video' | 'text' | 'audio' | undefined;

export const Main: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [releaseDate, setReleaseDate] = useState<number>();
  const [key, setKey] = useState<string>();
  const [content, setContent] = useState<string>();
  const [contentType, setContentType] = useState<TFileType>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [contractAddress, setContractAddress] = useState<string>('');
  const { t } = useTranslation();

  useQuery<TGetReleaseTime>(GET_RELEASE_TIME, {
    onCompleted: (data) => {
      setReleaseDate(new Date(data.getReleaseTime.releaseTime).getTime());
    },
  });

  useQuery<TGetSmartContractAddress>(GET_SMART_ADDRESS, {
    onCompleted: (data) => {
      setContractAddress(data.getSmartContractAddress.smartContractAddress);
    },
  });

  const [getVideo] = useLazyQuery<TGetDecryptedFileUrls>(
    GET_DECRYPTED_FILES_URLS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const contentUrl = data.getDecryptedFileUrls?.fileUrls[2];

        if (contentUrl) {
          let dataType: TFileType = undefined;

          fetch(contentUrl, {
            method: 'GET',
            mode: 'cors',
          })
            .then((resp): Promise<string | Blob> | undefined => {
              const type = resp.headers.get('Content-Type');

              if (type?.includes('text')) {
                dataType = 'text';
                return resp.text();
              }
              if (type?.includes('video')) {
                dataType = 'video';
                return resp.blob();
              }
              if (type?.includes('image') || type?.includes('img')) {
                dataType = 'img';
                return resp.blob();
              }
              if (type?.includes('audio')) {
                dataType = 'audio';
                return resp.blob();
              }
            })
            .then((res) => {
              switch (dataType) {
                case 'text': {
                  typeof res === 'string' && setContent(res);
                  break;
                }
                case 'video':
                case 'img':
                case 'audio': {
                  const url = window.URL.createObjectURL(res);

                  setContent(url);
                }
              }

              setContentType(dataType);
              setIsLoading(false);
              setIsModalOpen(true);
            })
            .catch(() => {
              setIsLoading(false);
              setError(t('fileRequestError'));
            });
        }
      },
      onError: () => {
        setIsLoading(false);
        setError(t('urlRequestError'));
      },
    },
  );

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [error]);

  const onModalOpen = () => {
    setError(undefined);
    setIsLoading(true);
    getVideo({
      variables: {
        key: key,
      },
    });
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onKeyChange = (value: string) => {
    setKey(value);
  };

  return (
    <div
      className={`container ${
        isModalOpen ? 'container-close' : 'container-open'
      }`}>
      <div className="mainPage-lang">
        <LangSelect />
      </div>

      <div className="mainPage-head">
        <a className="logo" href="https://ilink.dev/">
          iLink
        </a>

        <h1 className="header">{t('header')}</h1>

        <p className="mainPage-subHeader">{t('subHeader')}</p>
      </div>

      <p className="mainPage-description">
        <a
          className="mainPage-description-link"
          href="https://bscscan.com/address/0x3D037124F33D4eF350fe25Df1855954Df15496f3">
          {t('descriptionLink')}
        </a>
        <span>{t('description1')}</span>
      </p>

      <div className="mainPage-timer">
        <Timer date={releaseDate} />
      </div>

      <div className="mainPage-address">
        <Clipboard label={t('clipboardLabel')} text={contractAddress} />
      </div>

      <div className="mainPage-key">
        <div className="mainPage-input">
          <Input
            placeholder={t('keyPlaceholder')}
            value={key}
            onChange={onKeyChange}
            label={t('keyLabel')}
          />
        </div>

        <div className="mainPage-button">
          <Button
            disabled={!releaseDate || new Date().getTime() < releaseDate}
            text={t('buttonText')}
            onClick={onModalOpen}
            isLoading={isLoading}
          />
        </div>
      </div>

      <div className="capsule">
        <img src={capsule} alt="time capsule" className="capsule-img" />
      </div>

      <OpenCapsuleModal
        content={content}
        contentType={contentType}
        isOpen={isModalOpen}
        onClose={onModalClose}
      />

      <ToastContainer />
    </div>
  );
};
