import React from 'react';
import './App.css';
import { Main } from './components/Main';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

function App() {
  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL,
    cache: new InMemoryCache(),
    headers: {
      'referrerPolicy': 'unsafe_url'
    }
  });

  return (
    <div className="layout">
      <ApolloProvider client={client}>
        <Main />
      </ApolloProvider>
    </div>
  );
}

export default App;
