import React, { useEffect, useState } from 'react';
import './styled.css';
import { TimerItem } from '../TimerItem';
import { useTranslation } from 'react-i18next';

const monthInMs = 60 * 60 * 24 * 1000 * 30;
const dayInMs = 60 * 60 * 24 * 1000;
const hourInMs = 60 * 60 * 1000;
const minuteInMs = 60 * 1000;

export type TTimer = {
  date?: number;
};

export const Timer: React.FC<TTimer> = ({ date = Date.now() }) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const difference = date - currentTime;
  const months = Math.floor(difference / monthInMs);
  const days = Math.floor((difference % monthInMs) / dayInMs);
  const hours = Math.floor((difference % dayInMs) / hourInMs);
  const minutes = Math.floor((difference % hourInMs) / minuteInMs);
  const seconds = Math.floor((difference % minuteInMs) / 1000);

  const getNoun = (val: number) => {
    const cases = [2, 0, 1, 1, 1, 2];
    if (val % 100 > 4 && val % 100 < 20) {
      return 2;
    }
    if (val % 10 < 5) {
      return cases[val % 10];
    }
    return 2;
  };

  return (
    <div className="timer">
      <TimerItem number={months} text={t(`months${getNoun(months)}`)} />
      <TimerItem number={days} text={t(`days${getNoun(days)}`)} />
      <TimerItem number={hours} text={t(`hours${getNoun(hours)}`)} />
      <TimerItem number={minutes} text={t(`min${getNoun(minutes)}`)} />
      <TimerItem number={seconds} text={t(`sec${getNoun(seconds)}`)} />
    </div>
  );
};
