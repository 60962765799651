import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from './locales/en-us.json';
import RU from './locales/ru-ru.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: EN,
  },
  ru: {
    translation: RU,
  },
};

void i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    // debug: true,
    resources,
  });

export default i18next;
